.js-fade-section {
	opacity: 0;
	transform: translateY(20px);
	transition: all .6s ease;
	&_1 {
		transition-delay: 100ms;
	}
	&_2 {
		transition-delay: 300ms;
	}
	.loaded & {
		opacity: 1;
		transform: translateY(0);
	}
}