.nm-banner-section {
	margin-bottom: 133px;

	&__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-flow: row wrap;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: space-between;
	}
}

.nm-banner-link {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex-flow: column nowrap;
	justify-content: space-between;
	width: 45%;
	max-width: 50%;
	flex-basis: 45%;
	flex-grow: 1;
	min-height: 360px;
	max-height: 360px;
	margin: 0 12px 24px;
	padding: 37px 24px 59px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	text-decoration: none;
	&:nth-child(odd) {
		margin-left: 0;
	}
	&:nth-child(even) {
		margin-right: 0;
	}

	&__title {
		color: white;
		.light();
		font-size: @34;
		line-height: 1.41;
		margin-bottom: -10px;
	}
	&__desc {
		color: white;
		.regular();
		font-size: @16;
		line-height: 1.5;
		max-width: 60%;
	}

	&:hover {
		.nm-btn__overlay {
			opacity: 1;
		}
		.nm-btn_transparent {
			border: 1px solid rgba(255, 255, 255, 1);
		}
	}
}

// 34 -> 26
@media screen and (max-width: 1030px) {
	.nm-banner-link {
		width: 45%;
		flex-basis: 45%;
		padding-bottom: 30px;
		min-height: 400px;
		max-height: 400px;
		&__title {
			font-size: @26;
			margin-bottom: 20px;
		}
		&__desc {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 770px) {
	.nm-banner-section {
		margin-bottom: 60px;

		&__container {
			flex-direction: column;
			align-items: center;
		}
	}
	.nm-banner-link {
		margin-bottom: 30px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		max-width: 100%;
		flex-basis: 100%;
		min-height: 360px;
		max-height: 400px;
		&__title {
			font-size: @26;
			margin-bottom: 20px;
		}
		&__desc {
			margin-bottom: 20px;
		}
	}
}
