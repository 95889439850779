.nm-customers-section {
	margin-bottom: 138px;
	&__container {

	}
	&__title {
		color: #222222;
		.light();
		font-size: @34;
		margin-bottom: 30px;
	}
}

.nm-customers {
	&__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__item {

	}
	&__item-img {
		max-width: 100%;
		max-height: 3rem;
	}
}

@media screen and (max-width: 770px) {
	.nm-customers-section {
		margin-bottom: 88px;
		&__title {
			font-size: @26;
			text-align: center;
		}
	}

	.nm-customers {
		&__list {
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
		&__item {
			min-width: 150px;
			margin-bottom: 30px;
		}
	}
}