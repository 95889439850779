// 1. Header
// 2. Top row
// 3. Menu toggler


// 1. header
.nm-header {
	background: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: transform 0.4s ease-out;
	.shifted & {
		transform: translateX(272px);
	}
	&_shadow {
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
	}
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 31px;
		padding-bottom: 30px;
	}
	&__left {
		display: flex;
		margin-left: 50px;
		transition: all 0.4s ease-out;
		align-items: center;
		.shifted & {
			margin-left: 0;
		}
	}
	&__logo {
		display: block;
		.svg-icon('neq-logo', 140px, 47px);
		margin-right: 30px;
	}
	&__top-menu-toggler {
		width: 30px;
		height: 30px;
		cursor: pointer;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			.svg-icon('icon-caret', 14px, 9px);
			transform: rotate(0);
			margin-left: -7px;
			margin-top: -4px;
			.shifted-top & {
				transform: rotate(180deg);
			}
		}
	}

	&__nav {

	}
	&__nav-item {
		color: #111111;
		.regular();
		font-size: @16;
		text-decoration: none;
		border-bottom: 1px solid rgba(34, 34, 34, 0.15);
		margin-left: 34px;
		&:first-child {
			margin-left: 0;
		}
		&:hover {
			border-bottom: none;
		}
	}
}

// 2. Top row
.nm-header {
	// Top row
	&__top-row {
		background: #f9f9f9;
		height: 90px;
		margin-top: -90px;
		transition: all .4s ease-out;
		.shifted-top & {
			margin-top: 0;
		}
	}
	&__top-row-container {
		height: 90px;
		display: flex;
		align-items: center;
	}
	&__top-row-logo {
		display: flex;
		align-items: center;
		text-decoration: none;
	}
	&__top-row-logo-image {
		margin-right: 10px;
		min-width: 96px;
		max-width: 120px;
	}
	&__top-row-logo-img {
		max-width: 100%;
	}
	&__top-row-logo-text {
		color: #aaaaaa;
		font-size: @14;
	}
}

// 3. Menu toggler
.nm-menu-toggler {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: fixed;
	left: calc(~"50% - 621px + 20px");
	top: 43px;
	z-index: 300;
	transition: all 0.4s ease-out;
	&:hover {
		opacity: .5;
	}

	.shifted-top & {
		top: 43px + 90px;
	}
	.shifted & {
		left: 56px;
		top: 43px;
	}
	&__burger {
		position: relative;
		width: 24px;
		height: 24px;
		transform: rotate(0deg);
		transition: all .5s ease-in-out;
		cursor: pointer;
	}
	&__burger-line {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: rgba(20,20,20,0.8);
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all .25s ease-in-out;
		.shifted & {
			background: white;

		}
		&:nth-child(1) {
			top: 2px;
			transform-origin: left center;
			.shifted & {
				transform: rotate(45deg);
				top: 3px;
				left: 4px;
			}
		}
		&:nth-child(2) {
			top: 10px;
			transform-origin: left center;
			.shifted & {
				width: 0;
				opacity: 0;
			}
		}
		&:nth-child(3) {
			top: 18px;
			transform-origin: left center;
			.shifted & {
				transform: rotate(-45deg);
				top: 20px;
				left: 4px;
			}
		}
	}
	&__menu-toggler-text {
		text-transform: uppercase;
		.regular();
		font-size: @13;
		margin-left: 12px;
		color: white;
		display: none;
		.shifted & {
			display: block;
		}
	}
}

@media screen and (max-width: 1242px) {
	// 3. Menu toggler
	.nm-menu-toggler {
		left: 20px;
	}
}

@media screen and (max-width: 1100px) {
	// 1. header
	.nm-header {
		&__container {
			justify-content: flex-end;
			padding-top: 17px;
			padding-bottom: 15px;
		}
		&__logo {
			.svg-icon('neq-logo', 96px, 36px);
			margin-right: 20px;
		}
		&__top-menu-toggler {
			width: 20px;
			height: 20px;
		}

		&__nav {
			display: none;
		}
	}

	// 3. Menu toggler
	.nm-menu-toggler {
		top: 24px;
		.shifted-top & {
			top: 24px + 90px;
		}
		.shifted & {
			left: 56px;
			top: 24px;
		}
	}
}