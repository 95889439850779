.svg-icon(@icon, @width, @height) {
	background: svg('@{icon}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}

.svg-icon-param(@icon, @width, @height, @param) {
	background: svg('@{icon}', '@{param}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}

.gradient() {
	background: #5acc3a;
	background: -webkit-linear-gradient(135deg, #5acc3a 0%, #1ea7ab 100%);
	background: linear-gradient(135deg, #5acc3a 0%, #1ea7ab 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5acc3a', endColorstr='#1ea7ab',GradientType=1 );
	box-shadow: 0 7px 14px rgba(90, 204, 58, 0.4), 0 1px 3px rgba(90, 204, 58, 0.3);

	&:before {

	}

	&:active {
		box-shadow: none;
	}
}