.nm-site-menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #5ACC3A;
	width: 272px;
	box-sizing: border-box;
	z-index: 200;
	transition: transform 0.4s ease-out;
	transform: translateX(-272px);
	padding: 112px 48px 24px 56px;
	overflow-y: auto;

	.shifted & {
		transform: translateX(0);
	}

	&__link {
		color: white;
		display: block;
		cursor: pointer;
		padding: 10px 0;
		font-size: @22;
		.light();
		text-decoration: none;
		transition: opacity 0.1s ease-out;
		&:hover {
			opacity: .5;
		}
	}
}

@media screen and (max-width: 1100px) {
	.nm-site-menu {
		padding-top: 82px;
	}
}