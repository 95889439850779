.nm-text-section {
	margin-bottom: 62px;
	&__text {
		color: #111111;
		.light();
		font-size: @34;
		font-weight: 300;
		line-height: 1.41;
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__green {
		color: #5acc3a;
	}
	&__icon-route {
		.svg-icon('icon-route', 36px, 34px);
		display: inline-block;
		vertical-align: baseline;
	}
	&__icon-around {
		.svg-icon('icon-around', 46px, 31px);
		display: inline-block;
		vertical-align: baseline;
	}
}

@media screen and (max-width: 770px) {
	.nm-text-section {
		&__text {
			font-size: @26;
		}
		&__icon-route {
			transform: scale(.8);
			transform-origin: center bottom;
		}
		&__icon-around {
			transform: scale(.8);
			transform-origin: center bottom;
		}
	}
}

@media screen and (max-width: 550px) {
	.nm-text-section {
		&__text {
			font-size: @20;
		}
		&__icon-route {
			transform: scale(.7);
		}
		&__icon-around {
			transform: scale(.7);
		}
	}
}