.nm-news-section {
	margin-bottom: 89px;
	&__container {

	}
	.swiper-slide {
		width: 270px;
	}
}

.nm-news-item {
	&__date {
		color: #111111;
		.regular();
		font-size: @12;
		letter-spacing: 1px;
		margin-bottom: 4px;
		text-transform: uppercase;
	}
	&__text {
		.regular();
		font-size: @16;
		line-height: 1.5;
		color: #111111;
		margin-bottom: 4px;
	}
	&__author {
		font-family: "Open Sans";
		font-size: @16;
		color: #aaaaaa;
	}
}