.nm-footer {
	padding-bottom: 109px;
	transition: transform 0.4s ease-out;
	.shifted & {
		transform: translateX(272px);
	}
	&__container {
		display: flex;
		justify-content: space-between;
	}
	&__col {

	}
	&__copy {
		color: #111111;
		.regular();
		font-size: @16;
		line-height: 1.5;
		margin-bottom: 24px;
	}
	&__address {
		color: #111111;
		.regular();
		font-size: @16;
		line-height: 1.5;
		text-decoration: none;
		max-width: 200px;
		display: block;
		&:hover {
			color: lighten(#111, 40%);
		}
	}
	&__phone {
		color: #111111;
		.regular();
		font-size: @16;
		line-height: 1.5;
		p {
			color: #111111;
			.regular();
			font-size: @16;
			line-height: 1.5;
			margin-bottom: 12px;
		}
	}
	&__contact-links {
		display: flex;
		flex-direction: column;
		margin-bottom: 19px;
	}
	&__contact-link-item {
		margin-bottom: 12px;
	}
	&__contact-link {
		color: #111111;
		.regular();
		font-size: @16;
		line-height: 1.5;
		margin-bottom: 8px;
		border-bottom: 1px solid rgba(34, 34, 34, 0.15);
		text-decoration: none;
		&:hover {
			border-bottom: 1px solid rgba(34, 34, 34, .5);
		}
	}
	&__socials {

	}
	&__social-link {
		display: inline-block;
		vertical-align: bottom;
		text-decoration: none;
		margin-right: 12px - 4px;
		//background: #787878;
		&_instagram {
			.svg-icon('icon-instagram', 24px, 24px);
			&:hover {
				opacity: .8;
				//.svg-icon-param('icon-instagram', 24px, 24px, '[fill]:#787878');
			}
		}
		&_fb {
			.svg-icon('icon-fb', 24px, 24px);
			&:hover {
				opacity: .8;
				//.svg-icon-param('icon-fb', 24px, 24px, '[fill]:#787878');
			}
		}
	}

	&__nav {

	}
	&__nav-item {
		margin-bottom: 12px;
	}
	&__nav-link {
		color: #111111;
		.regular();
		font-size: @16;
		line-height: 1.5;
		margin-bottom: 8px;
		border-bottom: 1px solid rgba(34, 34, 34, 0.15);
		text-decoration: none;
		&:hover {
			border-bottom: 1px solid rgba(34, 34, 34, .5);
		}
	}

	&__project {
		margin-bottom: 34px;
		text-decoration: none;
		display: block;
		text-decoration: none;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__project-icon {
		display: block;
		margin-bottom: 9px;
	}
	&__project-icon-img {
		max-width: 100%;
		max-height: 24px;
	}
	&__project-title {
		color: #b3b3b3;
		.regular();
		font-size: @12;
		line-height: 1.08;
		.nm-footer__project:hover & {
			color: #898989;
		}
	}
}

@media screen and (max-width: 770px) {
	.nm-footer {
		padding-bottom: 20px;
		&__container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
		}
		&__col {
			width: 100%;
			margin-bottom: 50px;
			text-align: center;
		}
		&__project-icon {
			margin-left: auto;
			margin-right: auto;
		}
		&__address {
			margin-left: auto;
			margin-right: auto;
		}
	}
}