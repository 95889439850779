.nm-slider-section {
	margin-bottom: 96px;
}

.nm-slider {
	&__slide {
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		padding-top: 83px;
		background-attachment: fixed;
		display: block;
		text-decoration: none;
	}
	&__slide-date {
		color: white;
		.regular();
		font-size: @16;
		letter-spacing: 2px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	&__slide-title {
		margin-bottom: 24px;
	}
	&__slide-title-inner {
		color: white;
		.light();
		font-size: @34;
		a & {
			border-bottom: 1px solid rgba(255, 255, 255, 0.25);
		}
	}
	&__slide-desc {
		max-width: 586px;
		color: white;
		.regular();
		font-size: @16;
		line-height: 1.5;
	}
}

@media screen and (max-width: 600px) {
	.nm-slider {
		&__slide {
			background-attachment: scroll;
		}
	}
}