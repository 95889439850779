// BUTTONS
.nm-btn {
	transition: all .1s ease;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border: none;
	background: transparent;
	height: 48px;
	white-space: nowrap;
	padding: 0 16px;
	border-radius: 4px;
	box-shadow: 0 7px 14px rgba(90, 204, 58, 0.4), 0 1px 3px rgba(90, 204, 58, 0.3);
	.gradient();

	text-align: center;

	color: white;
	.bold();
	font-size: 14px;
	letter-spacing: 1.17px;
	line-height: 48px;
	text-transform: uppercase;

	transition: all .2s ease;

	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		outline: none;
	}

	&_transparent {
		border: 1px solid rgba(255, 255, 255, 0.5);
		background: none;
		box-shadow: none;
		&:hover {
			border: 1px solid rgba(255, 255, 255, 1);
		}
	}

	&_block {
		padding: 0;
		display: block;
		width: 100%;
	}
	&_no-shadow {
		box-shadow: none;
		&_hover {
			box-shadow: none;
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #5acc3a;
		opacity: 0;
		border-radius: 4px;
		transition: all .2s ease;
		.nm-btn:hover & {
			opacity: 1;
		}
	}
	&__text {
		position: relative;
		z-index: 20;
	}
}

@media screen and (max-width: 500px) {
	.nm-btn {
		&_big {
			height: 48px;
			padding: 0 18px;
			line-height: 48px;
			font-size: 14px;
		}
	}
}
