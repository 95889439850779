.nm-projects-section {
	margin-bottom: 64px;
	&__container {

	}
	&__title {
		color: #222222;
		.light();
		font-size: @34;
		margin-bottom: 32px;
	}
}

.nm-projects {
	&__list {
		display: flex;
	}
	&__item {
		position: relative;
		margin-right: 24px;
		width: calc(~"(100% - 24px) / 2");
		margin-bottom: 24px;
		background: white;
		border: 1px solid #f1f1f1;
		color: #222222;
		&:nth-child(2n) {
			margin-right: 0;
		}

		&_bg {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			border: none;
			color: white;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0,0,0,.35);
			}
		}
	}

	&__item-inner {
		width: 100%;
		padding-bottom: 45%;
		height: 0;
		position: relative;
	}

	&__item-content {
		z-index: 20;
		padding: 36px 24px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__item-date {
		color: inherit;
		.regular();
		font-size: @12;
		letter-spacing: 1px;
		margin-bottom: 7px;
	}
	&__item-text {
		color: inherit;
		.regular();
		font-size: @16;
		line-height: 1.5;
	}
	&__item-title {
		position: absolute;
		bottom: 34px;
		left: 25px;
		color: inherit;
		.bold();
		font-size: @16;
	}
}

@media screen and (max-width: 1030px) {
	.nm-projects {
		&__item-inner {
			padding-bottom: 70%;
		}
	}
}

@media screen and (max-width: 770px) {
	.nm-projects-section {
		&__title {
			font-size: @26;
		}
	}

	.nm-projects {
		&__list {
			flex-direction: column;
		}
		&__item {
			width: 100%;
			margin-right: 0;
		}
		&__item-inner {
			padding-bottom: 45%;
		}
	}
}

@media screen and (max-width: 550px) {
	.nm-projects {
		&__item-inner {
			padding-bottom: 70%;
		}
	}
}

@media screen and (max-width: 440px) {
	.nm-projects {
		&__item-inner {
			padding-bottom: 100%;
		}
	}
}